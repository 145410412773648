<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout>
      <a-layout-content>
        <keep-alive :exclude="excludename">
          <!-- 主应用路由渲染出口 -->
          <router-view v-if="isRouterAlive"></router-view>
        </keep-alive>
        <div class="loading-content" v-if="dashboard_loading">
          <a-progress style="width: 60%; margin: 100px auto" :percent="defaultPercent"></a-progress>
        </div>
        <!-- 微前端子应用渲染出口 -->
        <div id="product-viewport"></div>
      </a-layout-content>
    </a-layout>
    <a-drawer
      class="appstore-drawer"
      placement="left"
      :closable="false"
      :visible="drawerVisible"
      width="88%"
      :bodyStyle="{
        height: 'calc(100% - 100px)',
        padding: '0px',
        overflow: 'auto',
      }"
      @close="onClose"
    >
      <template slot="title">
        <div class="appstore-drawer-title flex-ct between">
          <div class="appstore-drawer-title-search">
            <!-- <a-input :placeholder="$t('请输入菜单名称')" style="width: 350px" /> -->
            <a-auto-complete
              class="global-search"
              style="width: 350px"
              :placeholder="$t('请输入菜单名称')"
              option-label-prop="title"
              @select="onSelect"
              @search="handleSearch"
            >
              <template slot="dataSource">
                <a-select-option v-for="item in dataSource" :key="item.view">
                  {{ item.name }}
                </a-select-option>
                <a-select-option class="empty" key="empty" v-if="isEmpty">
                  <a-empty />
                </a-select-option>
              </template>
              <a-input>
                <a-button
                  slot="suffix"
                  style="margin-right: -12px"
                  class="search-btn"
                  type="primary"
                >
                  <a-icon type="search" />
                </a-button>
              </a-input>
            </a-auto-complete>
          </div>
          <div class="appstore-drawer-title-icon">
            <a-icon type="close" @click="onClose" />
          </div>
        </div>
      </template>
      <div class="appstore-drawer-body flex">
        <div class="drawer-menu">
          <a-menu style="width: 200px" @click="clickMenuHandle">
            <a-menu-item v-for="item in subMerchantAppList" :key="item.appCode">
              {{ item.name }}
            </a-menu-item>
          </a-menu>
        </div>
        <div class="sub-menu-list">
          <div class="sub-menu-item mb10" v-for="item in subMenuList" :key="item.view">
            <div class="sub-menu-title infoTitle">
              <div class="leftLine"></div>
              {{ item.name }}
            </div>
            <div class="item-children-list flex">
              <div
                class="item-children"
                v-for="child in item.children"
                :key="child.view"
                @click="goToView(item.view, child.view)"
              >
                <span>{{ child.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-drawer>
  </a-layout>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { Layout, Empty } from 'ant-design-vue'
import { getSystemName } from '@/common'
import { switchApp } from '@/qiankun/apps'

const excludename = ''

export default {
  Name: 'Home',
  components: {
    'a-empty': Empty,
    'a-layout': Layout,
    'a-layout-content': Layout.Content,
  },
  computed: {
    ...mapState([
      'exclude',
      'userSession',
      'dashboard_loading',
      'drawerVisible',
      'subMerchantAppList',
    ]),
    routeName() {
      return this.$route.name
    },
    currentModule() {
      return this.global.currentModule ? this.global.currentModule.view : this.global.currentModule
    },
  },
  data() {
    return {
      excludename,
      collapsed: false,
      isRouterAlive: true,
      ready: false,
      guideBarShow: false,
      currentSession: null,
      defaultPercent: 0,
      timer: null,
      showIcon: true,
      subMenuList: [],
      activeKey: '',
      dataSource: [],
      allMenuList: [],
      isEmpty: false,
    }
  },
  methods: {
    ...mapMutations(['setState', 'currentApp']),
    changeCollapsed() {
      this.collapsed = !this.collapsed
    },
    reload(newVal) {
      this.isRouterAlive = false
      this.excludename = newVal
      this.$nextTick(() => {
        this.excludename = excludename
        this.setState({
          exclude: null,
        })
        this.isRouterAlive = true
      })
    },
    showGuideBar(data) {
      this.guideBarShow = data
    },
    cancelGuide() {
      this.guideBarShow = false
    },
    toWorkOrder() {
      this.$refs.workOerderRef.open()
    },
    onClose() {
      this.setState({
        drawerVisible: false,
      })
    },
    clickMenuHandle({ key }) {
      this.activeKey = key
      let list = this.subMerchantAppList.find((item) => item.appCode == key)
      this.subMenuList = list.menuList
    },
    handleShowIcon() {
      this.showIcon = !this.showIcon
    },
    goToView(view1, view2) {
      if (this.activeKey !== this.currentApp.appCode) {
        localStorage.removeItem('panes')
        localStorage.removeItem('floatTabList')
      }
      this.onClose()
      if (getSystemName(this.activeKey) === 'BI') {
        switchApp(
          this.activeKey,
          `/${getSystemName(this.activeKey)}/qbiDashboard?parentMenu=${view1}&childMenu=${view2}`
        )
      } else {
        switchApp(this.activeKey, `/${getSystemName(this.activeKey)}/${view1}/${view2}`)
      }
    },
    onSelect(value) {
      if (value === 'empty') return
      let arr = value.split('-')
      this.activeKey = arr[0]
      this.goToView(arr[1], arr[2])
    },
    handleSearch(value) {
      this.isEmpty = false
      this.dataSource = value ? this.searchResult(value) : []
    },
    searchResult(value) {
      if (this.allMenuList.length === 0) {
        this.setAllMenuList()
      }
      let result = this.allMenuList.filter((item) => item.label.includes(value))
      if (result.length === 0) {
        this.isEmpty = true
      }
      return result
    },
    setAllMenuList() {
      let menuList = []
      this.subMerchantAppList.forEach((item) => {
        item.menuList.forEach((subItem) => {
          subItem.children.forEach((childItem) => {
            menuList.push({
              label: childItem.name,
              name: `${item.name} > ${subItem.name} > ${childItem.name}`,
              view: `${item.appCode}-${subItem.view}-${childItem.view}`,
            })
          })
        })
      })
      this.allMenuList = menuList
    },
  },
  watch: {
    exclude(newVal) {
      // console.log('newVal', newVal)
      if (newVal) {
        this.reload(newVal)
      }
    },
    routeName(val) {
      if (this.global.currentModule && val == this.global.currentModule.view) {
        this.guideBarShow = true
      } else {
        this.guideBarShow = false
      }
    },
    dashboard_loading: {
      handler(val) {
        if (val) {
          this.timer = setInterval(() => {
            if (this.defaultPercent == 90) return
            this.defaultPercent += 10
          }, 500)
        } else {
          clearInterval(this.timer)
          this.timer = null
          this.defaultPercent = 0
        }
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
#components-layout-demo-custom-trigger {
  height: 100%;
}

.erp-layout-sider-collapsed {
  flex: 0 0 80px;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}

.erp-layout-content {
  overflow-y: auto;
  scroll-behavior: smooth;
}

.spin-main {
  height: 300px;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#product-viewport {
  height: 100%;
}

.appstore-drawer {
  top: 60px;
}

.appstore-drawer-body {
  height: 100%;
  .drawer-menu {
    height: 100%;
    background-color: #fafafa;
    overflow: auto;
    user-select: none;
    font-size: 14px;
    color: #212121;
  }
}
.sub-menu-list {
  padding: 20px;
  flex: 1;
  height: 100%;
  overflow: auto;
  user-select: none;
  .item-children-list {
    flex-wrap: wrap;
  }
  .item-children {
    width: 200px;
    min-height: 28px;
    margin-right: 30px;
    padding: 4px 8px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 14px;
    color: #333;
    margin-top: 6px;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
</style>

<style>
#product-viewport > div {
  height: 100%;
}
</style>
